<template>
	<div class="login-body">
		<div class="login-panel p-fluid">
			<div class="login-panel-header">
				<img src="assets/layout/images/logo-slim.png" alt="serenity"/>
			</div>
			<div class="login-panel-content">
                <form @submit.prevent="signIn">
                    <div class="p-grid">
                        <div class="p-col-12" ref="appName">
                            <h1>Nutricia Otthonápoló 2020</h1>
                        </div>
                        <div class="p-col-12">
                            <span class="p-float-label">
                                <InputText id="username" ref="usernameField" type="text" v-model="username"/>
                                <label for="username">Email</label>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <span class="p-float-label">
                                <InputText id="password" type="password" v-model="password"/>
                                <label for="password">Jelszó</label>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <span class="p-float-label">
                                <InputText id="password2" type="password" v-model="password2"/>
                                <label for="password2">Megerősítés</label>
                            </span>
                        </div>
                        <div class="p-col-12">
                            <Button type="submit"
                                :label="(isLoading)?'':'Bejelentkezés'" 
                                style="width:100%"  
                                :icon="(isLoading)?'pi pi-spin pi-spinner':''"
                                iconPos="middle"/>
                        </div>
                        <Message v-if="error.message" style="width: 100%" severity="error">{{error.message}}</Message>
                    </div>
                </form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            username: "",
            password: "", password2: "",
            token: "",
            error: {
                isError: "",
                message: ""
            },
            isLoading: false
        }
    },

    mounted() {
        //console.log(this.$route)
        try{
            this.username = this.$route.query.email
            this.token = this.$route.params.token
        }catch{
            this.username = ""
            this.token = ""
        }
        this.$refs.usernameField.$el.focus()
    },

    computed: {
        ...mapGetters('auth', [
            'authenticating',
            'authenticationError',
            'authenticationErrorCode'
        ])
    },

    methods: {
        ...mapActions('auth', [
            'loginWithAccessLink'
        ]),

        signIn: async function(){
            if (this.username != '' && this.password != '') {
                this.isLoading = true

                if(!await this.loginWithAccessLink({
                                    token: this.token, 
                                    email: this.username, 
                                    password: this.password, 
                                    password_confirmation: this.password2 
                    })){

                    this.error.isError = "is-danger"
                    this.error.message = this.authenticationError
                    
                }
                this.isLoading = false
            }else{
                    this.error.isError = "is-danger"
                    this.error.message = "Adja meg felhasználónevét és jelszavát"
            }
        }
    },

    watch: {
        username() {
                if(this.error.isError){
                    this.error.isError = ""
                    this.error.message = ""
                }
        },
        password() {
                if(this.error.isError){
                    this.error.isError = ""
                    this.error.message = ""
                }
        }
    }

}
</script>

<style scoped>

</style>
